.order-configurator {
  .title {
    span {
      flex: 1 0 60%;
    }
    input {
      flex: 1 0 30%;
    }
  }
  .form-wrapper {
    .header {
      display: flex;
      p {
        margin: 10px;
        margin-bottom: 15px;
        flex: 1 1 33.33333%;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-rigth: 0;
        }
      }
    }
    .input-row {
      display: flex;
    }
  }
  .button-wrapper {
    padding: 25px 0;
    display: flex;
    button, .import-button {
      color: #fff;
      background: #000;
      cursor: pointer;
      position: relative;
      -webkit-animation: all .25s ease-in-out;
      animation: all .25s ease-in-out;
      height: 45px;
      border: 0;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      padding: 0 20px;
      outline: none;
      display: flex;
      align-items: center;
      &:disabled {
        cursor: initial;
        background: #999;
      }
    }
    .import-button {
      margin-left: 10px;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 992px) {
  .order-configurator {
    .form-wrapper {
      .input-row {
        display: block;
      }
      input {
        margin: 10px 0;
      }
    }
  }
}
