body {
  background: #f7f7f8;
}

.content {
  display: flex;
  padding: 30px;
}

.order-configurator, .order-schedule {
  flex: 1 1 50%;
  margin: 10px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 10px #e1e4e6;
  border-radius: 8px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.subtitle {
  font-size: 15px;
  color: #999;
  margin: 45px 0 20px 0;
}


input {
  outline: 0;
  height: 45px;
  border: 0;
  border-radius: 8px;
  background-color: #f2f2f2;
  font-size: 14px;
  padding: 0 14px;
  margin: 0 10px;
  width: 100%;
  box-sizing: border-box;
}

input:first-child {
   margin-left: 0;
}

input:last-child {
   margin-rigth: 0;
}

@media (max-width: 992px) {
  .content {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .content {
    display: block;
  }
  .order-configurator, .order-schedule {
    padding: 25px;
  }
}
